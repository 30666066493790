@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.fade-in-up-leave-to {
  opacity: 0;
  /*transition: opacity 0.3s;*/
}

.fade-in-up-enter {
  opacity: 0;
  transform: translate3d(0, 10px, 0);
}

.fade-in-up-enter-to {
  opacity: 0;
  animation-duration: 0.7s;
  animation-fill-mode: both;
  animation-name: fadeInUp;
}
